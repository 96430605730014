// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-earn-js": () => import("./../../../src/pages/earn.js" /* webpackChunkName: "component---src-pages-earn-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-handshake-whitepaper-js": () => import("./../../../src/pages/handshake-whitepaper.js" /* webpackChunkName: "component---src-pages-handshake-whitepaper-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-registry-tos-js": () => import("./../../../src/pages/registry-tos.js" /* webpackChunkName: "component---src-pages-registry-tos-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-wrapped-hns-js": () => import("./../../../src/pages/wrapped-hns.js" /* webpackChunkName: "component---src-pages-wrapped-hns-js" */),
  "component---src-templates-aboutdlinks-js": () => import("./../../../src/templates/aboutdlinks.js" /* webpackChunkName: "component---src-templates-aboutdlinks-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-register-js": () => import("./../../../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */)
}

